import { Drawer } from '@components';
import useOpenableState from '@hooks/useOpenableState';
import { doSendConsultantEmail, doSetModalConsultant } from '@redux-modules/consultant/actions';
import { CMSConsultant } from '@redux-modules/consultant/interfaces';
import { doOpenIntercomChat } from '@redux-modules/intercom/actions';
import { getBookingUrl } from '@utils/miscUtils';
import { useBreakpoints } from '@utils/viewportUtils';
import { useRouter } from 'next/router';
import React from 'react';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Locale, useLocale } from 'src/locales';
import { AppState } from 'src/redux/reducers';
import { BodyOverflowHiddenStyle } from 'src/components/GlobalStyles';
import ConsultantTile from './components/ConsultantTile';
import ContactBubble from './components/ContactBubble';
import { getGeneralEmailContent } from './helpers';
import { breakpoints } from 'src/components/Theme';

const getDefaultConsultant: (locale: Locale) => Pick<CMSConsultant, 'phoneNumber' | 'url' | 'emailAddress'> = (
  locale: Locale
) => {
  const urlDataMap: Record<Locale, { lang: string; storeId: number }> = {
    en: {
      lang: 'en-US',
      storeId: 907
    },
    de: { lang: 'de-CH', storeId: 907 },
    fr: { lang: 'fr-CH', storeId: 918 },
    it: { lang: 'it-CH', storeId: 919 }
  };
  return {
    phoneNumber: '+41 44 365 51 51',
    url: `https://www.s-ge.com/${locale}/book-meeting?partner=switzerlandglobalenterprise&isFrame=true&lang=${urlDataMap[locale].lang}&type=appointment&store_id=${urlDataMap[locale].storeId}&lang-switcher=false`,
    emailAddress: 'exporthelp@s-ge.com'
  };
};

const Contact: React.FC<ContactProps> = props => {
  const locale = useLocale();
  const intl = useIntl();
  const router = useRouter();

  const defaultConsultant = getDefaultConsultant(locale);
  const { custom1 } = useBreakpoints(breakpoints.mobile);
  const [isOpen, { open, set, close }] = useOpenableState(false);
  const [message, setMessage] = React.useState('');
  const [isEmailSent, setIsEmailSent] = React.useState(false);

  const handleLiveChatClick = React.useCallback(() => {
    close();
    props.doOpenIntercomChat();
  }, []);
  const handleClose = React.useCallback(() => {
    if (props.shouldClearOnClose) {
      props.doSetModalConsultant(undefined);
    }
    setMessage('');
    setIsEmailSent(false);
  }, [props.shouldClearOnClose]);

  const bookingUrl = React.useMemo(
    () => getBookingUrl(props.modalConsultant?.url, props.user, locale, props.marketGuide?.name),
    [props.modalConsultant?.url, props.user, locale, props.marketGuide?.name]
  );

  const extraBottomSpacing = React.useMemo(() => router.route.startsWith('/business-opportunities/edit'), [
    router.route
  ]);

  const sendEmailToConsultant = () => {
    if (!props.user) {
      return;
    }
    const body = `
      ${props.emailData?.additionalContent || ''}
      ${getGeneralEmailContent(props.user, intl, message)}
    `;
    props.doSendConsultantEmail({
      subject: intl.formatMessage({ id: 'consultantContactSubject' }),
      body,
      marketId: props.emailData?.marketId,
      email: props.modalConsultant?.emailAddress || defaultConsultant.emailAddress
    });
    setMessage('');
    setIsEmailSent(true);
  };
  return ReactDOM.createPortal(
    <>
      {!props.isChatOpen && (
        <ContactBubble
          onClick={open}
          photo={props.modalConsultant?.image?.teaserSm}
          extraBottomSpacing={extraBottomSpacing}
        />
      )}
      <Drawer
        showDrawer={isOpen}
        handleChange={set}
        handleClose={handleClose}
        right
        width={custom1 ? '100%' : undefined}
      >
        <ConsultantTile
          consultant={props.modalConsultant}
          bookingUrl={bookingUrl}
          handleLiveChatClick={handleLiveChatClick}
          handleMessageButtonClick={sendEmailToConsultant}
          defaultConsultant={defaultConsultant}
          message={message}
          handleMessageChange={setMessage}
          loading={props.loading}
          error={props.error}
          isEmailSent={isEmailSent}
        />
      </Drawer>
      {isOpen && <BodyOverflowHiddenStyle />}
    </>,
    document.body
  );
};

export type AppProps = ReturnType<typeof mapStateToProps>;

const mapStateToProps = ({ consultantReducer, marketGuideReducer, userReducer, intercomReducer }: AppState) => ({
  marketConsultant: consultantReducer.marketConsultant,
  marketGuide: marketGuideReducer.marketGuide,
  user: userReducer.user,
  isChatOpen: intercomReducer.isChatOpen,
  shouldClearOnClose: consultantReducer.shouldClearOnClose,
  modalConsultant: consultantReducer.modalConsultant,
  emailData: consultantReducer.consultantEmailData,
  loading: consultantReducer.consultantEmailSending,
  error: consultantReducer.consultantEmailError
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doOpenIntercomChat,
      doSetModalConsultant,
      doSendConsultantEmail
    },
    dispatch
  );

export type ContactProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
